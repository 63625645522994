<template>
  <b v-if="scaleDiff !== 0 && oldValue !== 0" :class="{increase: scaleDiff > 0, decrease: scaleDiff < 0}">{{ formattedDiff }}</b>
</template>

<script>
import { toPercent, toScalableFixed } from '@/utils/math'

export default {
  name: 'DataTransition',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    percentage: Boolean,
    fixedCount: {
      type: Number,
      default: 4,
    },
    formatter: {
      type: Function,
    },
  },
  data () {
    return {
      diff: 0,
    }
  },
  computed: {
    oldValue () {
      return this.value - this.diff
    },
    formattedDiff () {
      const formatter = this.formatter
      if (typeof formatter === 'function') {
        return formatter(this.value, this.diff, this.fixedCount)
      }
      if (this.percentage) {
        if (this.oldValue === 0) {
          return ''
        }
        return toPercent(this.diff / this.oldValue)
      }
      return toScalableFixed(this.diff, this.fixedCount)
    },
    scaleDiff () {
      return Math.round(this.diff * 10000)
    },
  },
  watch: {
    value (newVal, oldVal) {
      this.diff = newVal - oldVal
    },
  },
}
</script>

<style lang="less" scoped>

@import '../assets/styles/variables';

.increase {
  color: @green;

  &:before {
    content: '+'
  }
}

.decrease {
  color: #e77165;

}
</style>
