/**
 * 可伸缩的小数点保留位数
 * @param {number} x
 * @param {number} y
 * @param {boolean} [trim=true]
 * @returns {string}
 */
export function toScalableFixed (x, y = 2, trim = true) {
  let ret = ''
  if (typeof x !== 'number' || Math.ceil(x) === x) {
    ret += x
  } else {
    ret += x.toFixed(y)
  }

  return trim ? (ret).replace(/(\.\d*[^0]+)0+$/g, '$1') : ret
}

/**
 * 四舍五入保留小数并兼容负数，常用于把浮点数格式化为百分数
 * @param {string|number} x
 * @param {number} y = 2 保留小数
 * @returns {string}
 */
export function toRoundFixed (x, y = 2) {
  if (typeof x === 'string') {
    x = parseFloat(x)
  }
  const sign = (x < 0) ? -1 : 1
  const n = Math.pow(10, y)

  return toScalableFixed(Math.round(x * n * sign) / n * sign, y)
}

/**
 * 把数字转化为百分数
 * @param {number|string} x
 * @returns {string}
 */
export function toPercent (x) {
  if (x === undefined || x === '') {
    return ''
  }
  return toRoundFixed(x * 100) + '%'
}
